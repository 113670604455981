<template>
  <div ref="print" id="printMe" style="width: 100mm;height: 68mm;padding-left: 4mm;padding-top: 2mm">
    <div style="line-height: 7.5mm;font-size:3.6mm;border: 0.6mm black solid;font-weight: 600">
      <div style="border-bottom: 0.6mm black solid;">
        <!-- margin:0 10mm;display:block;float: left;border-right: 0.6mm black solid; -->
        <div style="float: left; border-right: 0.6mm black solid;height: 7.5mm;">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zfyLogo.png" style="width: 7.5mm;height: 7.5mm;"/>
        </div>
        <div style="margin: 0 auto;width: fit-content">
          {{dataList[0].value}}
        </div>
       </div>
      <div style="display: flex;border-bottom: 0.6mm black solid;">
         <div style="flex: 1">
           <div style="display: flex;border-bottom: 0.6mm black solid">
             <div style="border-right: 0.6mm black solid;padding-left: 2mm;padding-right: 4mm">{{dataList[1].key}}:{{dataList[1].value}}</div>
             <div style="flex: 1;padding-left: 2mm;border-right: 0.6mm black solid;">编号:{{deviceNo}}</div>
           </div>
           <div style="border-bottom: 0.6mm black solid;border-right: 0.6mm black solid;padding-left: 2mm">{{dataList[2].key}}:{{dataList[2].value}}</div>
           <div style="border-bottom: 0.6mm black solid;border-right: 0.6mm black solid;padding-left: 2mm">{{dataList[3].key}}:{{dataList[3].value}}</div>
           <div style="border-right: 0.6mm black solid;padding-left: 2mm">{{dataList[4].key}}:{{dataList[4].value}}</div>
         </div>
        <div style="width: 30mm">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/qlfx2.png" style="height: 30.7mm;display:block;margin: 0 auto;"/>
        </div>
      </div>
      <div style="display: flex">
        <div style="flex: 1">

          <div style="border-bottom: 0.6mm black solid;border-right: 0.6mm black solid;padding-left: 2mm">制造单位:中国辐射防护研究院</div>
          <div style="border-bottom: 0.6mm black solid;border-right: 0.6mm black solid;padding-left: 2mm">电&#8195;&#8195;话:0351-2203175</div>
          <div style="border-right: 0.6mm black solid;padding-left: 2mm">生产日期:{{createTime}}</div>
        </div>
        <div style="width: 30mm;text-align: center">
          <div style="line-height: 4mm;font-size: 8px;">国家工信部授权标识</div>
          <vue-qr
              :size="1000"
              :margin="0"
              :auto-color="true"
              :dot-scale="1"
              :text="`https://api.86122m.com/h?h=${handle}`"
              class="codeImage"
              style="width: 15mm;height: 15mm;display: block;margin: 0 auto"
          />
          <div style="line-height: 4mm;font-size: 8px">{{handle}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr'

export default {
  components: {VueQr},
  props: {
    dataList: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    handle: {
      type: String,
      default: '86.122.2/XXX'
    },
    date: {
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss'
    },
    deviceNo:{
      type: String
    },
    createTime:{
      type: String
    }
  },
  data() {
    return {
      printHandle: '86.122',
      barcodeOption: {
        displayValue: false
      }
    }
  },
  methods: {
    print() {

    }
  }
}
</script>
