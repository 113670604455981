<template>
  <div>
    <el-dialog
      title="下单印刷回执"
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="visible"
      :close-on-click-modal="false"
    >
      <div class="tip">提示：下单后服务人员会跟您联系沟通印刷细节</div>
      <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      >
      <el-table-column
        prop="lotNumber"
        label="批号（数量）"
        width="180"
        align="ceneter">
        <template slot-scope="scope">
          <span>{{scope.row.lotNumber}}</span><span>({{scope.row.num}})</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="printOrderCreateTime"
        label="下单时间"
        width="180"
        align="ceneter"
        >
      </el-table-column>
      <el-table-column
        prop="printTime"
        label="印刷时间"
        align="ceneter"
       >
        <template slot-scope="scope">
          <span class="cell_span">{{scope.row.printTime ? scope.row.printTime : '--'}}</span>
        </template>
      </el-table-column>
    </el-table>
    </el-dialog>
  </div>
</template>
<script>
// import { fetchPageData } from '@/api/customPage.js'

export default {
  components:{
  },
  data(){
    return {
      visible: false,
      tableData: []
    }
  },
  computed: {
  },
  created(){
  },
  methods:{
    init(data) {
      this.tableData = data
    },
    openDialog() {
      this.visible = true
    },
    // async getPageData() {
    //   const result = await fetchPageData(this.pageId)
    // }
  }
  
}
</script>
<style scoped>
.tip{
  color: #f00;
  line-height: 30px;
}
.cell_span{
  display: inline-block;
  text-align: center;
  width: 100%;
}
</style>