<template>
  <div class="orderList-view view pa24">
      <el-row :gutter="24">
          <el-col :xl="4" :lg="4">
            <div class="d-flex align-items-center nowrap">
              <span class="searchLabel">标识: </span>
              <el-input placeholder="请输入标识" prefix-icon="el-icon-search" v-model="searchHandle"></el-input>
            </div>
          </el-col>
          <el-col :xl="4" :lg="4">
            <div class="d-flex align-items-center nowrap">
              <span class="searchLabel">是否回读: </span>
              <el-select
                  v-model="searchIsRead"
                  placeholder="请选择"
                  >
                  <el-option label="请选择" value=""></el-option>
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="不需要回读" :value="false"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :xl="4" :lg="4">
              <el-button type="primary" @click="searchData">搜索</el-button>
          </el-col>
          <el-col :xl="12" :lg="12" class="btnBox">
              <el-button type="primary"  @click="showPrintAll()">批量打印</el-button>
              <el-button type="primary" v-if="isBind" @click="handleExportBindClick()">关联标识导出</el-button>
              <el-button type="primary" @click="exportClick()">批量导出</el-button>
              <el-button type="primary" @click="handlePrinting()" :disabled="!hasPermi">下单印刷</el-button>
              <el-button type="primary" @click="handleReadDialog()" :disabled="!hasPermi" v-if="isShowRead">数据回读</el-button>
          </el-col>
      </el-row>
      <!-- :spanMethod="objectSpanMethod" -->
      <commonTable :tableData="tableData"
                   @handleSizeChange="handleSizeChange"
                   @handleCurrentChange="handleCurrentChange"
                   :currentPage="pageNum"
                   :loading="loading"
                   :total="total">
          <template v-slot:table>
              <el-table-column prop="title" align="center" label="页面名称"></el-table-column>
              <el-table-column prop="createTime" width="100" align="center" label="创建时间">
                  <!-- <template slot-scope="scope">
                    {{scope.row.createTime}}
                  </template> -->
              </el-table-column>
              <el-table-column prop="handle" align="center" label="标识">
              </el-table-column>

              <el-table-column align="center" label="标识二维码" width="150">
                  <template slot-scope="scope">
                      <vue-qr
                          :size="200"
                          :margin="20"
                          :auto-color="true"
                          :dot-scale="1"
                          :text="`https://a.86122m.com/h?h=${scope.row.handle}`"
                          class="codeImage"
                      />
                      <!-- <img class="codeImage" :src="scope.row.codeImg"/> -->
                  </template>

              </el-table-column>

              <el-table-column align="center" label="验证码">
                  <template slot-scope="scope">
                      <span>{{scope.row.verificationCode}}</span>
                  </template>
              </el-table-column>
              <el-table-column align="center" label="是否回读">
                  <template slot-scope="scope">
                      <span>{{scope.row.isRead ? '是' : '不需要回读'}}</span>
                  </template>
              </el-table-column>
              <el-table-column prop="scanNum" align="center" label="可扫码次数">
              </el-table-column>
              <el-table-column prop="useScanNum" align="center" label="剩余扫码次数">
                  <template slot-scope="scope">
                      <span>{{scope.row.scanNum - scope.row.useScanNum >= 0? (scope.row.scanNum - scope.row.useScanNum) : -1}}</span>
                  </template>
              </el-table-column>
              <el-table-column prop="lotNumber" align="center" label="批次号">
              </el-table-column>
              <el-table-column prop="" align="center" label="公众号文章引用链接" width="100">
                  <template slot-scope="scope">
                      <el-button type="text" size="mini" class="tag-read" @click="copyLink('pages/custom/page?h='+scope.row.handle)"
                                 :data-clipboard-text="handleLink">复制链接
                      </el-button>
                      <!-- <span>{{`pages/custom/page?h=${scope.row.handle}`}}</span> -->
                  </template>
              </el-table-column>
              <el-table-column prop="inCode" align="center" label="内码" width="100" v-if="hasInCode">
              </el-table-column>
              <template v-if="isBind">
                  <el-table-column v-if="type == 2 || type == 4 || type == 5" prop="boxHandle" align="center" label="关联箱标">
                  </el-table-column>
              </template>
              <el-table-column prop="areas" align="center" label="销售地区" width="150">
                <template slot-scope="scope">
                  <div v-for="item in scope.row.areas" :key="item.id" >
                      <span>{{item.address}}、</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="remark" align="center" label="备注">
              </el-table-column>
              <el-table-column align="center" label="操作" width="300">
                  <template slot-scope="scope">
                      <template v-if="scope.row.isDel">
                          <el-button size="mini">删除</el-button>
                      </template>
                      <el-button type="text" size="mini" @click="lookPage(scope.row)">查看</el-button>
                      <el-button type="text" size="mini" class="tag-read" @click="copyLink('https://a.86122m.com/h?h='+scope.row.handle)"

                                 :data-clipboard-text="handleLink">复制链接
                      </el-button>
                      <el-button type="text" @click="lookScanLog(scope.row)">
                          扫码记录
                      </el-button>
                      <el-button type="text" v-if="scope.row.verificationCode" @click="goVerPageList(scope.row)">
                          验证记录
                      </el-button>
                      <el-button type="text" size="mini" @click="getQrCodeImage(scope.row, scope.$index)">下载二维码</el-button>
                      <el-button type="text" @click="updateScanNum(scope.row)" :disabled="!hasPermi">修改可扫码次数</el-button>
                      <el-button type="text" v-if="isBind && type == 3" @click="handleCheckBind(scope.row)">查看绑定</el-button>
                      <!-- <el-button type="text" @click="updateArea(scope.row)" :disabled="!hasPermi">修改销售地区</el-button> -->
                      <el-button type="text" @click="disableHandle(scope.row)" :disabled="!hasPermi">
                        {{scope.row.enabled?'禁用标识':'启用标识'}}
                      </el-button>
                      <!-- <el-button type="text" v-if="isBind" @click="handleExportBind(scope.row)">导出</el-button> -->
                      <!-- <el-button type="text" size="mini" @click="handlePrinting(scope.row)">下单印刷</el-button> -->
                    <!-- <el-button type="text" @click="printLabel(scope.row)">打印</el-button> -->
                    <el-button type="text" @click="showPrintAll(scope.row)">打印</el-button>
                  </template>
              </el-table-column>
          </template>
      </commonTable>

      <!--修改可扫码次数弹窗-->
      <el-dialog
              title="修改可扫码次数"
              width="600px"
              ref="form"
              :modal-append-to-body="false"
              :visible.sync="dialogFormVisible"
              :close-on-click-modal="false"
      >
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
              <el-form-item prop="scanNum" label="扫码次数">
                  <el-input v-model="ruleForm.scanNum" placeholder="请输入扫码次数"></el-input>
              </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="closeDialog">取 消</el-button>
              <el-button class="btncss" @click="confirmUpdateScanNum">确 定</el-button>
          </div>
      </el-dialog>

<!--   批量打印-->
    <el-dialog :visible.sync="printAllVisible" title="批量打印" :append-to-body="true" width="600px">
      <el-select
          v-model="printType"
          default-first-option
          placeholder="请选择打印规格"
          @change="printChange"
      >
        <el-option label="亚银纸（60*35）" :value="2" key="2"></el-option>
<!--          <el-option label="中辐院(100*70)" :value="1"></el-option>-->
        <el-option v-for="item in zfyPrintList" :label="item.title" :value="item.title" :key="item.title"/>
      </el-select>
      <el-button style="display: inline-block;margin-left: 20px" v-print="'#printAll'">打印</el-button>
      <div ref="printAll" class="printAllWrap" id="printAll" style="height: 100%">
        <div v-if="printType ==2">
            <div v-for="item in printAllList" :key="item.handle">
              <print3070 :handle="item.handle" :title="item.pageName"/>
            </div>
        </div>
        <div v-else>
          <div class="printItem" v-for="item in printAllList" :key="item.handle">
            <device-print-component v-if="curPrintItem.type===1"  :data-list="curPrintItem.data"  :handle="item.handle" :date="printRow.createTime" :device-no="item.deviceNo" :create-time="item.createTime"></device-print-component>
            <zfy-print-component v-else-if="curPrintItem.type===2" :data-list="curPrintItem.data" :handle="item.handle" :date="printRow.createTime" :device-no="item.deviceNo" :create-time="item.createTime"/>
          </div>
        </div>
      </div>
    </el-dialog>
<!--      单个打印-->
    <!-- <el-dialog :visible.sync="printShow" title="打印" :append-to-body="true" width="600px">
      <el-form :model="printParam">

        <el-form-item label="标签类型">
          <el-select v-model="printParam.type" placeholder="请选择标签类型" @change="printChange">
            <el-option label="亚银纸（60*35）" :value="2"></el-option>
            <el-option v-for="item in zfyPrintList" :label="item.title" :value="item.title" :key="item.title"/>
          </el-select>
        </el-form-item>
      </el-form>
      <div v-if="printParam.type == 2">
        <div id="printMe">
          <device-print-component id="printMe" :handle="printRow.handle" :date="printRow.createTime" :device-no="printRow.deviceNo" :create-time="printRow.createTime"></device-print-component>
        </div>
      </div>
      <div v-else>
        <div id="printMe">
          {{curPrintItem.type}}
          <print3070 v-if="curPrintItem.type===1"   :handle="printRow.handle" :title="printRow.title"></print3070>
        </div>
      </div>
      <el-button  v-print="'#printMe'" style="margin-top: 10px">打印</el-button>

    </el-dialog> -->
      <!-- 导出-->
      <el-dialog title="导出"
          :modal-append-to-body="false"
          :visible.sync="dialogExportVisible"
          :close-on-click-modal="false"
      >
          <el-row >
              <el-button type="primary" plain @click="exportNow()">导出当前页</el-button>
              <el-button type="primary" plain @click="exportAll()">导出所有数据</el-button>
          </el-row>
      </el-dialog>

      <!--查看弹窗----->
      <SeeCustomDialog ref="seeCustomDialog"></SeeCustomDialog>

      <!--下单印刷弹窗-->
      <OrderPrintDialog ref="orderPrintDialog"></OrderPrintDialog>

      <!--数据回读弹窗-->
      <ReadDialog ref="readDialog" :lotNumber="lotNumber"></ReadDialog>

      <!--查看绑定弹窗-->
      <BindDialog ref="bindDialog"></BindDialog>

      <!--扫码记录-->
      <ScanLogDialog ref="scanLogDialog"></ScanLogDialog>

      <!--修改销售地区-->
      <AreaDialog ref="areaDialog" @refresh="selectOrdersInfoPcAsync"></AreaDialog>
      <!--关联标识导出弹窗-->
      <el-dialog title="导出关联标识"
                :modal-append-to-body="false"
                :visible.sync="dialogExportTxtVisible"
                :close-on-click-modal="false"
      >
        <el-row >
          <el-radio v-model="txtType" label="1">
            一对多
            <el-tooltip placement="right" style="color: #0b0b0b">
              <div style="font-size: 15px" slot="content">一个箱标与多个关联标识展示为一行<br/>格式：箱标,箱标验证码,关联标识,关联标识验证码,关联标识,关联标识验证码,......。
              </div>
              <span><i style="font-size: 16px" class="el-icon-question" /></span>
            </el-tooltip>
          </el-radio>

          <el-radio v-model="txtType" label="2">
            一对一
            <el-tooltip placement="right" style="color: #0b0b0b">
              <div style="font-size: 15px" slot="content">一个箱标与一个关联标识展示为一行<br/>格式：箱标,箱标验证码,关联标识,关联标识验证码。
                <br/>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱标,箱标验证码,关联标识,关联标识验证码。
              </div>
              <span><i style="font-size: 16px" class="el-icon-question" /></span>
            </el-tooltip>
          </el-radio>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="dialogExportTxtVisible = false" >取 消</el-button>
          <el-button class="btncss"  @click="handleExportBind">导 出</el-button>
        </div>
      </el-dialog>
      <!--批量导出弹窗-->
      <el-dialog title="导出标识"
                :modal-append-to-body="false"
                :visible.sync="exportNoBindVisible"
                :close-on-click-modal="false"
      >
        <el-radio-group v-model="exportNoBindType">
          <el-radio label="excel">excel格式</el-radio>
          <el-radio label="txt">txt格式</el-radio>
        </el-radio-group>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="exportNoBindVisible = false" >取 消</el-button>
          <el-button class="btncss"  @click="handleExportNoBind">导 出</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
  import Clipboard from 'clipboard'
  import commonTable from "@/components/common/commonTable";
  import SeeCustomDialog from '@/components/seeCustomDialog.vue'
  import OrderPrintDialog from '@/components/orderPrintDialog.vue'
  import ReadDialog from '@/components/readDialog.vue'
  import BindDialog from './BindDialog.vue'
  import {fetchHandlePageList, fetchCreateHandle, fetchUpdateScanNum, fetchPrint, fetcExportHandle, fetcExportHandleTxt, fetchExportBind,fetchDisableHandle} from '@/api/customPage'
  import { showErrorToast } from "@/utils/utils";
  import DevicePrintComponent from "@/pages/createPageList/components/Print/DevicePrintComponent";
  import Print3070 from "@/pages/createPageList/components/Print/Print3070.vue";
  import ScanLogDialog from './ScanLogDialog'
  import Print from 'vue-print-nb'
  import AreaDialog from './AreaDialog'
  import {fetchprintHandleList} from "../../../api/customPage";
  // const QR = require('@/utils/weapp-qrcode.js')
  import VueQr from 'vue-qr'
  import zfyPrint from '@/json/zfyPrint.json'
  import ZfyPrintComponent from "@/pages/createPageList/components/Print/ZfyPrintComponent.vue";

  export default {
      name: "customlist",
      components: {
          ZfyPrintComponent,
          ScanLogDialog,
          DevicePrintComponent,
          Print3070,
          commonTable,
          SeeCustomDialog,
          OrderPrintDialog,
          ReadDialog,
          BindDialog,
          AreaDialog,
          VueQr
      },
    directives: {
      Print
    },
      data() {
          return {
            printType: zfyPrint[0].title,
            txtType:'1',
            dialogExportTxtVisible:false,
            exportNoBindType: 'excel',
            exportNoBindVisible: false,
             printParam: {type: 2},
             printShow: false,
            printRow: {},
              searchHandle: '', //检索标识
              searchIsRead: '', // 检索是否回读
              hasPermi: true,
              loading: false,
              total: 0, //总条数
              currentPage: 1, //当前页数
              start: '',
              end: '',
              tableData: [],
              pageNum: 1,
              pageSize: 10,
              exloading: true,
              totalNums: 1, // 分页，一共请求次数
              exPageSize: 10,
              customPageId: undefined,
              lotNumber: undefined,
              handleLink: undefined, // 复制链接
              dialogFormVisible: false,
              ruleForm: {
                  scanNum: '', // -1为无限
              },
              rules: {
                  scanNum: [{required: true, message: "请输入扫码次数", trigger: "blur"}],
              },
              curId: null,
              dialogExportVisible: false,
              exportList: [],
              isShowRead: false, // 回读数据
              isBind: false,
              type:'',
            printAllVisible:false,
            printAllList:[],
            zfyPrintList: zfyPrint,
            curPrintItem: zfyPrint[0]
          };
      },
      created() {
          if (this.$route.query) {
              this.customPageId = this.$route.query.customPageId
              this.lotNumber = this.$route.query.lotNumber
              this.isShowRead = this.$route.query.isRead
              this.searchHandle = this.$route.query.handle
              this.isBind = this.$route.query.isBind
              this.type = this.$route.query.type
              this.hasInCode = this.$route.query.hasInCode
              this.selectOrdersInfoPcAsync();
          }
          if(localStorage.getItem('isSourceAdmin') && JSON.parse(localStorage.getItem('isSourceAdmin'))) {
              this.hasPermi = false
          }
      },
      watch: {},
      filters: {},
      methods: {
        printChange(e){
          if(e !== 2) {
            this.curPrintItem=this.zfyPrintList.filter(item=>item.title===e)[0]
          }else {
            this.printType = 2
          }
          console.log('改变',this.curPrintItem)
        },
        //批量打印
        async showPrintAll(row){
          if(row) { // 单个
              fetchprintHandleList({pageId:this.customPageId,lotNumber:this.lotNumber, handle: row.handle}).then(response=>{
                  if (response.data && response.data.length){
                      row['deviceNo'] = response.data[0].deviceNo
                      row['createTime'] = response.data[0].createTime
                  }
                  this.printAllList = [row]
                  this.printAllVisible = true
              })
          }else { // 批量
            fetchprintHandleList({pageId:this.customPageId,lotNumber:this.lotNumber}).then(response=>{
              if (response.data){
                  this.printAllList = response.data
                  this.printAllVisible = true
              }
            })
          }

        },
        async disableHandle(row){
          console.log(row.enabled)
          var s;
          if (row.enabled){
            s = '禁用'
          }else {
            s = '启用'
          }
          this.$confirm("确定"+s+"该标识吗?", "提示", {
            onConfirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info ",
          })
              .then(() => {
                let param = {
                  handle:row.handle
                }
                fetchDisableHandle(param)
                    .then(res => {
                      if (res.code == 200) {
                        this.selectOrdersInfoPcAsync();
                        this.$message({
                          type: "success",
                          message: "操作成功！",
                        });
                      }
                    })

              })
        },
          updateArea(row) {
              this.$refs.areaDialog.openDialog(row)
          },
          lookScanLog(row) {
            this.$refs.scanLogDialog.openDialog(row)
          },
          printLabel(row){
             this.printRow=row;
             fetchprintHandleList({pageId:this.customPageId,lotNumber:this.lotNumber, handle: this.printRow.handle}).then(response=>{
                  if (response.data && response.data.length){
                    this.printRow['deviceNo'] = response.data[0].deviceNo
                    this.printRow['createTime'] = response.data[0].createTime
                  }
              })
             this.printShow=true;
          },
          handleCheckBind(data) {
              this.$refs.bindDialog.openDialog(data)
          },
        handleExportBindClick(){
          this.txtType = '1'
          this.dialogExportTxtVisible = true
        },
          async handleExportBind() {
            let params = {
                lotNumber:this.lotNumber,
                type:this.txtType
            }
            let res = await fetchExportBind(params)
            let ss = decodeURIComponent(res.fileName)
            let aa = ss.indexOf('=')
            let fileNmae = ss.substring(aa + 1)
            if(res) {
              this.downloadBlobFile(res.data, fileNmae, 'text/plain;charset=utf-8')
              this.dialogExportTxtVisible = false
            }
          },
          searchData() {
              this.pageNum = 1
              this.selectOrdersInfoPcAsync()
          },
          lookPage(data) {
              // this.$router.push({
              //     path: '/customPageShow',
              //     query: {customPageId: data.customPageId, handle: data.handle}
              // }) // this.customPageId
              this.$refs.seeCustomDialog.visible = true
              this.$refs.seeCustomDialog.init(data.customPageId)
          },
          copyLink(data) {
              this.handleLink = data // data.customPageId
              var clipboard = new Clipboard('.tag-read')
              clipboard.on('success', e => {
                  console.log('复制成功')
                  //  释放内存

                  // clipboard.destory()
              })
              clipboard.on('error', e => {
                  // 不支持复制
                  console.log('该浏览器不支持复制')
                  // 释放内存
                  // clipboard.listener.destory()
              })
          },
          async delPage(data) {
              this.$confirm("确定删除此页面吗?", "提示", {
                  onConfirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "info ",
              })
                  .then(() => {
                      fetchDeletePage(data.id)
                          .then(res => {
                              if (res.code == 200) {
                                  this.$message({
                                      type: "success",
                                      message: "操作成功！",
                                  });
                                  this.selectOrdersInfoPcAsync();
                              }
                          })

                  })
          },
          editPage(data) {
              this.$router.push({path: '/customPage', query: {id: data.id}})
          },
          goVerPageList(data) {
              this.$router.push({path: '/mkc/verPageList', query: {id: data.id, handle: data.handle}})
          },
          /**@method 获取页面列表 */
          async selectOrdersInfoPcAsync() {
              let params = {
                  handle: this.searchHandle ? this.searchHandle.trim() : '',
                  isRead: this.searchIsRead,
                  customPageId: this.customPageId,
                  lotNumber: this.lotNumber,
                  pageNum: this.pageNum,
                  pageSize: this.pageSize,
                  exPage: 1,
                  totalNums: 1, // 分页，一共请求次数
                  exPageSize: 10
              }
              const result = await fetchHandlePageList(params)
              // this.tableData = result.data
               this.tableData = result.data.list
              // this.tableData = result.data.list.map(item => {
              //     if (item.handle) {
              //         let codeUrl = 'https://api.86122m.com/h?h=' + item.handle
              //         item.codeImg = QR.drawImg(codeUrl, {
              //             typeNumber: 4,
              //             errorCorrectLevel: 'M',
              //             size: 196 // 创建尺寸，用于下载
              //         })
              //     }
              //     return item
              // })
              this.total = result.data.total
          },
          //当前页变更
          handleCurrentChange(num) {
              this.pageNum = num;
              this.selectOrdersInfoPcAsync();
          },
          //当前页查询数据条数变更
          handleSizeChange(num) {
              this.pageSize = num;
              this.pageNum = 1;
              this.currentPage = 1;
              this.selectOrdersInfoPcAsync();
          },
          // 修改失效次数
          updateScanNum(data) {
              this.curId = data.id
            this.ruleForm.scanNum = data.scanNum
              this.dialogFormVisible = true
          },
          confirmUpdateScanNum() {
              this.$refs.ruleForm.validate(async (valid) => {
                  if (valid) {
                      let params = {
                          id: this.curId,
                          scanNum: this.ruleForm.scanNum
                      }
                      fetchUpdateScanNum(params).then(res => {
                          if (res.code === 200) {
                              this.$message({
                                  message: '操作成功',
                                  type: 'success'
                              });
                              this.closeDialog()
                              this.selectOrdersInfoPcAsync();
                          }
                      })
                  }
              })
          },
          closeDialog() {
              this.ruleForm = {
                  scanNum: "",
              }
              this.$refs.ruleForm.resetFields();
              this.dialogFormVisible = false
          },
          handleReadDialog() {
              this.$refs.readDialog.openDialog()
          },
          /** 导出产品清单 */
          handleExportAmount() {
              let this_ = this
              this.$confirm('是否确认导出所有数据项?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
              }).then(function () {
                  this_.exportExcel()
              })
          },
          // async exportClick() {
          //   let params = {
          //       customPageId: this.customPageId,
          //       lotNumber: this.lotNumber,
          //     //   reCreate: true,
          //   }
          //     const result = await fetcExportHandle(params)
          //     if(result) {
          //       this.downloadBlobFile(result, '标识.xlsx', 'text/plain;charset=utf-8')
          //     }
          // },
          // 点击批量导出
          exportClick() {
            this.exportNoBindVisible = true
          },
          // 不关联标识批量导出
          async handleExportNoBind() {
            if(this.exportNoBindType == 'excel') {
              let params = {
                customPageId: this.customPageId,
                lotNumber: this.lotNumber,
              }
              const result = await fetcExportHandle(params)
              let ss = decodeURIComponent(result.fileName)
              let aa = ss.indexOf('=')
              let fileNmae = ss.substring(aa + 1)
              if(result) {
                this.downloadBlobFile(result.data, fileNmae, 'text/plain;charset=utf-8')
                this.exportNoBindVisible = false
              }

            }else {
              let params = {
                customPageId: this.customPageId,
                lotNumber: this.lotNumber,
              }
              const result = await fetcExportHandleTxt(params)
              let ss = decodeURIComponent(result.fileName)
              let aa = ss.indexOf('=')
              let fileNmae = ss.substring(aa + 1)
              if(result) {
                this.downloadBlobFile(result.data, fileNmae, 'text/plain;charset=utf-8')
                this.exportNoBindVisible = false
              }


            }
          },
          downloadBlobFile(data, name, type) {
              if (window.navigator.msSaveOrOpenBlob) {
                  // 兼容IE
                  try {
                      var blobObject = new Blob([encodeURI(data)]);
                      window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                  } catch (e) {
                      console.log(e);
                  }
              } else {
              var blob = new Blob([data], {
                  // type: "application/octet-stream;charset=UTF-8"
                  type: type
              });
              var downloadElement = document.createElement("a");
              // 下载的⽂件名
              downloadElement.download = name;
              // 创建下载的链接
              downloadElement.href = window.URL.createObjectURL(blob);
              // 点击下载
              downloadElement.click();
              document.body.appendChild(downloadElement);
              // 下载完成移除元素
              document.body.removeChild(downloadElement);
              // 释放掉blob对象
              window.URL.revokeObjectURL(downloadElement.href);
              }
          },
          exportNow() {
              this.exloading = true
              this.export2Excel(this.tableData)
          },
          exportAll() {
              this.exloading = true
              if (this.total > 50) {
                  this.exPageSize = 200
                  var tNum = this.total / this.exPageSize
                  this.totalNums = Math.ceil(tNum)

                  var totalList = []
                  for (let i = 1; i <= this.totalNums; i++) {
                      var d = {index: i}
                      totalList.push(d)
                  }
                  this.getAllData(totalList, this.exPageSize, 1)

              } else {
                  this.getAllData([], this.total, 0)
              }

          },
          async getAllData(totalList, pageSize, type) {
              // type=1, 导出全部； type0.导出当前页
              var self = this
              if (type == 1) {
                  var pList = []
                  for (const item of totalList) {
                      let params = {
                          customPageId: this.customPageId,
                          lotNumber: this.lotNumber,
                          pageNum: item.index,
                          pageSize: pageSize
                      }
                      const result = await fetchHandlePageList(params)
                      result.data.list.forEach((item) => {
                          if (item.handle) {
                              let codeUrl = 'https://api.86122m.com/h?h=' + item.handle
                              console.log('codeUrl', codeUrl)
                              item.codeImg = QR.drawImg(codeUrl, {
                                  typeNumber: 4,
                                  errorCorrectLevel: 'M',
                                  size: 169
                              })
                          } else {
                              item.codeImg = ''
                          }
                      })
                      console.log('result.data.list', result.data.list);
                      pList.push(result.data.list)
                  }
                  var arr2 = self.flatten(pList)
                  console.log(arr2);
                  setTimeout(() => {
                      self.export2Excel(arr2)
                  }, 1500)

              } else {
                  let params = {
                      customPageId: this.customPageId,
                      lotNumber: this.lotNumber,
                      pageNum: this.pageNum,
                      pageSize: this.total
                  }
                  const result = await fetchHandlePageList(params)
                  result.data.list.forEach((item) => {
                      if (item.handle) {
                          let codeUrl = 'https://api.86122m.com/h?h=' + item.handle
                          item.codeImg = QR.drawImg(codeUrl, {
                              typeNumber: 4,
                              errorCorrectLevel: 'M',
                              size: 100
                          })
                      } else {
                          item.codeImg = ''
                      }
                  })

                  this.export2Excel(result.data.list)
              }
          },
          flatten(arr) {
              var self = this
              return [].concat(...arr.map(x => Array.isArray(x) ? self.flatten(x) : x))
          },
          exportExcel(arr) {
              require.ensure([], () => {
                  const { export_json_to_excel } = require('../../../excel/Export2Excel.js');
                  console.log(export_json_to_excel);
                  const tHeader = ['页面名称', '模板类型', '标识批号', '标识编号','链接','验证码','二维码', '备注', '创建时间']; //
                  // 上面设置Excel的表格第一行的标题
                  const filterVal = ['title', 'typeName', 'lotNumber', 'handle', 'url', 'verificationCode','codeImg','remark', 'createTime']; //
                  // 上面的index、nickName、name是tableData里对象的属性
                  const list = arr.map(item => {
                      item.url= `https://api.86122m.com/h?h=${item.handle}`
                      return item
                  });  //把data里的tableData存到list
                  const data = this.formatJson(filterVal, list);
                  var titName =  "标识码列表_" + new Date().getTime()
                  this.exloading = false
                  export_json_to_excel(tHeader, data, titName);
              })
          },
          export2Excel(arr) {
              console.log('tableData', arr);
              require.ensure([], () => {
                  const {export2Excel} = require('../../../excel/table2Excel.js');
                  const tHeader = ['页面名称', '模板类型', '标识批号', '标识编号', '链接','验证码','备注', '创建时间']; //  '二维码',
                  // 上面设置Excel的表格第一行的标题
                  const filterVal = ['title', 'typeName', 'lotNumber', 'handle', 'url', 'verificationCode','remark', 'createTime']; // 'codeImg',
                  // 上面的index、nickName、name是tableData里对象的属性
                  var list = []
                  list = arr.map(item => {
                      item.url= `https://api.86122m.com/h?h=${item.handle}`
                      return item
                  });  //把data里的tableData存到list
                  const data = this.formatJson(filterVal, list);
                  var tname = "标识码列表_" + new Date().getTime() + '.xlsx'
                  this.exloading = false
                  export2Excel(tHeader, data, tname);
              })
          },
          formatJson(filterVal, jsonData) {
              return jsonData.map(v => filterVal.map((j) => {
                  if (j === 'handle') {
                      // return `https://idshanxi.com/#/prefixContent?h=${v[j]}`
                      return v[j]
                  } else {
                      return v[j]
                  }
              }))
          },
          // 获取二维码图片
          getQrCodeImage(data, index) {
              var src = document.getElementsByClassName('codeImage')[index].getAttribute('src')
              src = src.replace('image/gif', 'image/png')
              var a = document.createElement('a');
              a.href = src
              a.download = data.handle+'.png';
              a.click();
          },
          // 下单印刷
          handlePrinting() {
              this.$confirm("确定要下单印刷吗?", "提示", {
                  onConfirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "info ",
              })
              .then(() => {
                  let params = {
                  lotNumber: this.lotNumber,
                  }
                  fetchPrint(params).then(res => {
                      // 发送请求，请求成功弹出弹窗，再获取下单记录
                      this.$refs.orderPrintDialog.init([res.data])
                      this.$refs.orderPrintDialog.openDialog()
                  }).catch(error => {
                      showErrorToast(this, error.message)
                  })
              })
          }
      },
  };
</script>

<style scoped lang='scss'>
.btnBox{
  text-align: right;
}
.searchLabel{
  margin-right: 10px;
}
.codeImage{ // 展示尺寸 100
  width: 100px;
  height: 100px;
}
.mr10{
  margin-right: 10px;
}
.printAllWrap .printItem:last-child{
margin-bottom: 0;
}
.printItem{
//margin-bottom: 10px
}
</style>
<style>
@media print {html,body {height: inherit;}
}
</style>
